<template>
	<div class="col-12">
		<div class="dashboard-widget-tabs p-0">
			<div class="dashboard-widget-tabs-content mb-0" style="padding: 15px !important;">
				<h2>
					<i class="uil uil-bell"></i>
					هشدارها
				</h2>
				<div class="dashboard-widget-h-btns">
					<a href="" class="m-left m-top tgju-btn tgju-btn-success" @click.prevent="open_alert_add_modal()">
						<i class="uil uil-plus-circle"></i> افزودن هشدار
					</a>
				</div>
			</div>

			
			<slot v-if="widgetLoad">
				<div class="text-center p-2">
					<div class="widget-loading"></div>
				</div>
			</slot>
			<slot v-else>
				<div class="widget-min-box" list-widget-id="1">
					<div class="widget-min-box-list min-show">
						<slot v-if="widgetLoad">
							<div class="text-center p-2">
								<div class="widget-loading"></div>
							</div>
						</slot>
						<slot v-else>
							<div class="list-widget list-dash-items list-widget-structure">
								<div class="list-row l-head">

									<!-- <div class="list-col l-item">
										<span class="width-fix">نوع ارز</span>
									</div> -->
									<div class="list-col l-title">
										<span>فهرست هشدارها</span>
									</div>
									<div class="list-col l-item"><span class="width-fix">ویرایش</span></div>
									<div class="list-col l-item"><span class="width-fix">حذف</span></div>
								</div>

								<div v-for="alert in alerts" :key="alert.id" class="list-row item-draggable hover" data-list-row-id="1">
									<div class="list-col l-title l-title-widget-structure">
										<span>{{ $helpers.json_pars(alert.asset)['label'] }}</span>
									</div>
									<!-- <div class="list-col l-title l-title-widget-structure">
										<span>{{ alert.title }}</span>
									</div> -->
									<div class="list-col l-item pointer" @click="open_alert_add_modal(alert.id)">
										<i class="uil-edit-alt"></i>
									</div>
									<div class="list-col l-item red-color pointer" @click="removeTodo(alert.id)">
										<i class="uil uil-trash remove-row"></i>
									</div>
								</div>


							</div>
						</slot>
					</div>
					
					<div class="widget-min-box-content">
						<slot v-if="widgetLoad">
							<div class="text-center p-2">
								<div class="widget-loading"></div>
							</div>
						</slot>
						<slot v-else>
							<div class="list-row l-head">
								<div class="list-col l-item">
									<span class="fa fa-arrow-right widget-min-back-to-list"></span>
								</div>
								<div class="list-col l-title">
									<span>تاریخچه</span>
								</div>

							</div>
							<div class="tgju-widget-content full-padding">
								<div v-for="history_item in history" :key="history_item.id" class="user-rows report-row">
									<div class="user-rows-image">
										<a href="" data-title="">
											<img src="https://static3.eghtesadnews.com/thumbnail/dtp1VVjUxAEQ/kzxwgq-JKBXtdnsW2DbwP_oAq30e7qnHfcABTZgEjDPeX92dFRcloYKQgQrR5ooNywpgzrCJSonkiG3pI_QvVJMtK7Jf75PBv4mlAx5BMvShLJzhhq960g,,/%D9%86%D8%B1%D8%AE+%D8%A7%D8%B1%D8%B2+%D8%AF%D9%84%D8%A7%D8%B1+%D8%B3%DA%A9%D9%87+%D8%B7%D9%84%D8%A7+%DB%8C%D9%88%D8%B1%D9%88.jpg">
										</a>
									</div>
									<a href="#" class="user-rows-inner">
										<div class="title">
											{{ history_item.title }}
											({{ $helpers.json_pars(history_item.asset)['label'] }})
										</div>
										<div class="subtitle">{{ history_item.body }} در {{ toRelative(history_item.created_at) }} </div>
									</a>
								</div>
								<div v-if="!history.length" class="popup-dash-empty">
									<i class="uil uil-exclamation" aria-hidden="true"></i>
									<span>هشداری موجود نمی باشد</span>
								</div>
							</div>
						</slot>


					</div>
					
				</div>
			</slot>




		</div>

		<!-- <div class="col-12">
			<div class="tgju-widget light">
				<div class="list-widget widget-head-page  list-row bold list-row-title"> 
					<div class="list-col l-title head-color">
						<span class="with-icon"><i class="uil uil-bell"></i>هشدارها</span>
						<span class="btn btn-primary btn-sm list-col-right-btn" @click="open_alert_add_modal()">
							<div class="fa fa-plus l-item-btn-pages"></div>
							افزودن هشدار
						</span>
					</div>
					<div class="list-col l-item pointer hover head-color">
						<span>
							<div class="fa fa-question-circle l-item-btn-pages"></div> راهنما
						</span>
					</div>
				</div>
				<div class="tgju-widget-content full-padding overflow-hidden">
					<slot v-if="widgetLoad">
						<div class="text-center p-2">
							<div class="widget-loading"></div>
						</div>
					</slot>
					<slot v-else>
						<div class="widget-min-box" list-widget-id="1">
							<div class="widget-min-box-list min-show">
								<slot v-if="widgetLoad">
									<div class="text-center p-2">
										<div class="widget-loading"></div>
									</div>
								</slot>
								<slot v-else>
									<div class="list-widget list-dash-items list-widget-structure">
										<div class="list-row l-head">

										
											<div class="list-col l-title">
												<span>فهرست هشدارها</span>
											</div>
											<div class="list-col l-item"><span class="width-fix">ویرایش</span></div>
											<div class="list-col l-item"><span class="width-fix">حذف</span></div>
										</div>

										<div v-for="alert in alerts" :key="alert.id" class="list-row item-draggable hover" data-list-row-id="1">
											<div class="list-col l-title l-title-widget-structure">
												<span>{{ $helpers.json_pars(alert.asset)['label'] }}</span>
											</div>
										
											<div class="list-col l-item pointer" @click="open_alert_add_modal(alert.id)">
												<i class="uil-edit-alt"></i>
											</div>
											<div class="list-col l-item red-color pointer" @click="removeTodo(alert.id)">
												<i class="uil uil-trash remove-row"></i>
											</div>
										</div>


									</div>
								</slot>
							</div>
							
							<div class="widget-min-box-content">
								<slot v-if="widgetLoad">
									<div class="text-center p-2">
										<div class="widget-loading"></div>
									</div>
								</slot>
								<slot v-else>
									<div class="list-row l-head">
										<div class="list-col l-item">
											<span class="fa fa-arrow-right widget-min-back-to-list"></span>
										</div>
										<div class="list-col l-title">
											<span>تاریخچه</span>
										</div>

									</div>
									<div class="tgju-widget-content full-padding">
										<div v-for="history_item in history" :key="history_item.id" class="user-rows report-row">
											<div class="user-rows-image">
												<a href="" data-title="">
													<img src="https://static3.eghtesadnews.com/thumbnail/dtp1VVjUxAEQ/kzxwgq-JKBXtdnsW2DbwP_oAq30e7qnHfcABTZgEjDPeX92dFRcloYKQgQrR5ooNywpgzrCJSonkiG3pI_QvVJMtK7Jf75PBv4mlAx5BMvShLJzhhq960g,,/%D9%86%D8%B1%D8%AE+%D8%A7%D8%B1%D8%B2+%D8%AF%D9%84%D8%A7%D8%B1+%D8%B3%DA%A9%D9%87+%D8%B7%D9%84%D8%A7+%DB%8C%D9%88%D8%B1%D9%88.jpg">
												</a>
											</div>
											<a href="#" class="user-rows-inner">
												<div class="title">
													{{ history_item.title }}
													({{ $helpers.json_pars(history_item.asset)['label'] }})
												</div>
												<div class="subtitle">{{ history_item.body }} در {{ toRelative(history_item.created_at) }} </div>
											</a>
										</div>
										<div v-if="!history.length" class="popup-dash-empty">
											<i class="uil uil-exclamation" aria-hidden="true"></i>
											<span>هشداری موجود نمی باشد</span>
										</div>
									</div>
								</slot>


							</div>
							
						</div>
					</slot>
				</div>
			</div>
		</div> -->
	</div>

</template>

<script>
	import moment from "moment-jalaali";
	export default {
		name: 'Alert',
		components: {},
		data: function () {
			return {
				alerts : [],
				history : [],
				widgetLoad: true,
			}
		},
		mounted() {
			this.getAlerts();
		},
		methods: {
			toRelative(date) {
				return moment(date).fromNow();
			},

			open_alert_add_modal(id = null) {
				let callbackEvent = this.$router.currentRoute.name + '_AddAlert_' + new Date().getTime();

				let action = ( id ) ? 'edit' : 'add';
				let alert = null;

				if ( id ) {
					let index = this.$helpers.getIndex(this.alerts, id);
					alert = this.alerts[index];
				}
				let modal_data = {
					modal: 'general_modal',
					callbackEvent,
					component: 'AlertAddEdit.vue',
					config: {
						title: ( action == 'edit' ) ? 'ویرایش هشدار' : 'افزودن هشدار',
						smallModal: true,
						data: {
							action,
							alert,
						},
					}
				};

				this.$root.$emit('open_modal', modal_data);

				this.$root.$on(callbackEvent, (response) => {
					/**
					 * آرایه ای از خطا های رایج
					 * */
					let server_response = {
						field_empty : 'فیلد ها الزامی می باشند',
						notification_required : 'حداقل یک نوع دریافت الزامی می باشد',
					};

					// if ( response.action == 'addAlert' ) {
						/**
						 * آماده سازی دیتای درخواست ای پی آی
						 */
						let api_data = {
							id : id,
							data : JSON.stringify(response.data.alert) 
						};

						/**
						 * ارسال درخواست ای پی آی
						 */
						this.$helpers.makeRequest('POST', '/alert/create-edit-alert' , api_data).then(api_response => {
							this.$helpers.api_alert(api_response);
											
							/**
							 * در صورتی در خواست ای پی آی خطایی داشت پیام مناسب نمایش دهد
							 */
							if(api_response.data.response.status === 'error') {
								Vue.prototype.$toast.error({
									title : 'خطا',
									message : server_response[api_response.data.message]
								});
								return 0;
							}

							/**
							 * در صورتی که در خواست ای پی آی موفقیت آمیز بود
							 */
							if(api_response.data.response.status === 'success') {
								Vue.prototype.$toast.success({
									title : 'موفق',
									message : ( action == 'edit' ) ? 'هشدار با موفقیت ویرایش شد' : 'هشدار با موفقیت افزوده شد',
								});

								this.getAlerts()
								return 0;
							}
						});
					// } 
				});
			},
			getAlerts() {
				
				let api_data = {};

				/**
				 * ارسال درخواست ای پی آی
				 */
				this.$helpers.makeRequest('GET', '/alert/alerts', api_data).then(api_response => {
					this.$helpers.api_alert(api_response);

					this.alerts = api_response.data.response.detail.alerts;
					this.history = api_response.data.response.detail.history;
					this.widgetLoad = false;
				});
			},
			removeTodo(id) {
				this.$helpers.confirm('هشدار حذف!', {
					message: 'آیا از حذف این مورد اطمینان دارید؟',
				}).then((confirmed) => {
					if ( confirmed ) {
						let index = this.$helpers.getIndex(this.alerts, id);

						this.alerts.splice(index, 1);
					

						this.$helpers.makeRequest('DELETE', '/alert/delete/' + id).then(api_response => {
							this.$helpers.api_alert(api_response);

							if ( api_response.data.response.status == 'success' ) {
								Vue.prototype.$toast.success({
									title : 'موفق',
									message : 'هشدار حذف شد'
								});
							}
						});	
					}
				});
			},
		},
			


	}
</script>